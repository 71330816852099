@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./meme.scss');

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// Variables
$font-family: 'Space Mono', monospace;
$bg-color: #000;
$text-color: #fff;
$highlight-color: #ffd700;
$warning-color: #ff0000;
$border-color: rgba(255, 255, 255, 0.8);

// Base Styles
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: $font-family;
    background: $bg-color;
    color: $text-color;
    min-height: 100vh;
}

// Layout Grid
.layout-grid {
    display: grid;
    grid-template-areas:
        'header header header'
        'left-top center right-top'
        'left-middle center right-middle'
        'left-bottom center right-bottom'
        'footer footer footer';
    gap: 20px;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 1024px) {
        grid-template-areas:
            'header header'
            'left-top right-top'
            'center center'
            'left-middle right-middle'
            'left-bottom right-bottom'
            'footer footer';
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
        grid-template-areas:
            'header'
            'center'
            'left-top'
            'right-top'
            'left-middle'
            'right-middle'
            'left-bottom'
            'right-bottom'
            'footer';
        grid-template-columns: 1fr;
    }
}

// Box Style
.box {
    border: 1px solid $border-color;
    padding: 15px;
    padding-bottom: 10px;
    background: $bg-color;
}

// Header
.header-box {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// Center Section
.center-section {
    grid-area: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    min-height: 100%;
}

.logo-text {
    font-size: clamp(2rem, 8vw, 5rem);
    font-weight: 700;
    line-height: 0.9;
    margin-bottom: 30px;

    .of {
        font-size: 0.5em;
        opacity: 0.8;
        margin: 10px 0;
    }
}

.app-button-community {
    padding: 10px 20px;
    border: 2px solid $text-color;
    margin-top: 10px;
    margin-bottom: 10px;
}

// Button Styles
.app-button {
    margin-bottom: 10px;
    padding: 15px 40px;
    font-size: 1.5rem;
    font-family: $font-family;
    border: 2px solid $text-color;
    color: $text-color;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        animation: shine 3s infinite;
    }
}

// Additional Text Styles
.coming-soon-text,
.urgent-alert {
    font-size: 0.8rem;
    color: $warning-color;
    animation: blink 2s infinite;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 10px;
}

.contract-address {
    margin-top: 10px;
    font-size: 0.9rem;
    color: $highlight-color;
    opacity: 0.8;
}

.ticker {
    color: $highlight-color;
    cursor: pointer;
}

// Loading Bar
.loading-bar {
    width: 100%;
    height: 20px;
    border: 1px solid $text-color;
    margin-top: 10px;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 54%;
        background: $text-color;
    }
}

// Footer
.footer-box {
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
}

.social-links {
    display: flex;
    gap: 15px;

    .social-link {
        width: 40px;
        height: 30px;
        border: 1px solid $text-color;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $text-color;
    }
}

// Animations
@keyframes shine {
    0% {
        left: -100%;
    }

    20% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes glitch {
    0% {
        transform: none;
    }

    7% {
        transform: skew(-0.5deg);
    }

    10% {
        transform: none;
    }

    27% {
        transform: none;
    }

    30% {
        transform: skew(0.5deg);
    }

    35% {
        transform: none;
    }
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% + 1200px));
        /* Adjust 1200px to match the container's width */
    }
}

.animate-slide {
    animation: slide 15s linear infinite;
}



/* Make the calendar icon white */
input[type='datetime-local']::-webkit-calendar-picker-indicator {
    filter: invert(1);
    /* Inverts the color of the calendar icon to white */
}



.bet-range {
    margin: 10px 0;
}

.bet-value {
    display: flex;
    align-items: center;
}

.wods-logo {
    width: 20px;
    /* Adjust size */
    height: auto;
    margin-right: 5px;
    /* Space between logo and input field */
}

.bet-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* For Chrome, Safari, and Edge */
input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #facc15;
    /* Yellow thumb */
    border-radius: 50%;
    /* Makes the thumb circular */
    cursor: pointer;
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background-color: #facc15;
    /* Yellow thumb */
    border-radius: 50%;
    cursor: pointer;
}

/* For Internet Explorer */
input[type="range"]::-ms-thumb {
    width: 16px;
    height: 16px;
    background-color: #facc15;
    /* Yellow thumb */
    border-radius: 50%;
    cursor: pointer;
}

.no-scrollbar {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer 10+ */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}