.meme-container {

    /* Custom range slider styling */
    .range-slider {
        background-color: theme('colors.gray.700');
        height: 0.25rem;
        /* equivalent to h-1 in Tailwind */
        border-radius: 0.5rem;
        /* equivalent to rounded-lg in Tailwind */
        cursor: pointer;
        appearance: none;
        /* Custom CSS */
    }

    .range-slider::-webkit-slider-thumb {
        width: 1rem;
        /* equivalent to w-4 in Tailwind */
        height: 1rem;
        /* equivalent to h-4 in Tailwind */
        border-radius: 9999px;
        /* equivalent to rounded-full in Tailwind */
        background-color: theme('colors.yellow.400');
        cursor: pointer;
        appearance: none;
        /* Custom CSS */
    }

    .range-slider::-moz-range-thumb {
        width: 1rem;
        /* equivalent to w-4 in Tailwind */
        height: 1rem;
        /* equivalent to h-4 in Tailwind */
        border-radius: 9999px;
        /* equivalent to rounded-full in Tailwind */
        background-color: theme('colors.yellow.400');
        border: 0;
        /* Custom CSS */
        cursor: pointer;
    }

    /* Prevent text selection while dragging */
    .draggable-item {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        touch-action: none;
    }

    /* Mobile optimizations */
    @media (max-width: 640px) {
        .controls-panel {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            margin-top: 8px;
            width: 200px;
        }

        .settings-button {
            right: -4px;
        }
    }
}